<template>
  <div class="popupBox">
    <div class="content">
      <slot>
        <van-action-sheet v-model:show="show" :title="title" :round="false" :close-on-click-overlay="false"
          @cancel="onCancel" @click-overlay="clickOverlay()">
          <div class="serviceBnt" v-if="[3,4].includes(Number(userInfo.agentType))"  @click="onContacts()">投诉商家</div>
          <div class="tips" v-if="actionType == 2">
            说明:充值余额以后，可以使用余额购买套餐，购买套餐以后卡片才可以使用。
          </div>
          <div class="packageInfo">
            <template v-if="actionType == 1">
              <div class="item">
                <div class="left title" style="width:17%;text-align:right;">卡号:</div>
                <div class="right desc">
                  {{ store.state.user.userInfo.virtualId || store.state.user.userInfo.iccid }}
                </div>
              </div>
              <div class="item">
                <div class="left title">套餐名称:</div>
                <div class="right desc">
                  {{ setMealObject.packageName }}
                </div>
              </div>
              <div class="item" v-if="payInfo.orderType == 'ordinaryOrder' && flowStatus != 'used'">
                <div class="left title">
                  {{
                    setMealObject.packageType == 4 ? "短信条数:" : "套餐流量:"
                  }}
                </div>
                <div class="right desc" v-if="setMealObject.cycle <= 1">
                  {{ setMealObject.flow
                  }}{{ setMealObject.packageType == 4 ? "条" : "MB" }}/{{
  setMealObject.cycleCategory == 1 ? "天" : setMealObject.cycleCategory == 2 ? "月"
  : setMealObject.cycleCategory == 3 ? "月" : setMealObject.cycleCategory >= 4 ? "年 " : "天"
}}
                </div>
                <div class="right desc" v-else>
                  <template v-if="setMealObject.stagingFlow">
                    {{ setMealObject.stagingFlow
                    }}{{ setMealObject.packageType == 4 ? "条" : "MB" }}/{{
  setMealObject.cycleCategory == 1 ? "天" : setMealObject.cycleCategory == 2 ? "月"
  : setMealObject.cycleCategory == 3 ? "月" : setMealObject.cycleCategory >= 4 ? "年 " : "天"
}}&emsp;
                  </template>
                  <span v-if="!isShowPackageInfoTips">
                    共{{ setMealObject.flow
                    }}{{ setMealObject.packageType == 4 ? "条" : "MB" }}/{{
  setMealObject.cycle
}}{{ setMealObject.cycleCategory == 1 ? "天" : setMealObject.cycleCategory == 2 ? "月"
  : setMealObject.cycleCategory == 3 ? "月" : setMealObject.cycleCategory >= 4 ? "年 " : "天" }}
                  </span>
                </div>
              </div>

              <div class="item" v-if="setMealObject.packageType === 3 &&
                payInfo.orderType == 'ordinaryOrder'
                ">
                <div class="left title">套餐语音：</div>
                <div class="right desc">
                  <u class="packageVoice" v-if="setMealObject.stagingVoice !== null">
                    {{ setMealObject.stagingVoice }}分钟/月
                  </u>
                  <span v-if="!isShowPackageInfoTips">
                    共{{ setMealObject.voice }}分钟/{{ setMealObject.cycle }} 月
                  </span>
                </div>
              </div>              
              <div class="item" v-if="showEffectTime">                
                <div class="left title">生效时间:</div>
                <div class="right desc colorTetx">
                  {{ setMealObject.virtualPeriod || effect || setMealObject.effect }}
                </div>
              </div>
              <div class="item">
                <div class="left title">套餐金额:</div>
                <div class="right desc colorTetx_">¥{{ chargeNum }}</div>
              </div>
              <div class="item" v-if="!stopPackage">
                <div class="left title">优惠券:</div>
                <div class="right decs " @click="openCoupon(setMealObject)">
                  <span v-if="couponObj.couponDistributionRecordId || setMealObject.couponDistributionRecordId">
                    <span class="colorTetx">-{{ couponObj.discountAmount || 0 }}</span>
                    <van-icon name="arrow" />
                  </span>
                  <span v-else style="color: rgba(0,0,0,0.4);">无可用券</span>
                </div>
              </div>
              <div class="item">
                <div class="left title">套餐说明:</div>
                <div class="right desc_remark">
                  {{ setMealObject.remarks }}
                </div>
              </div>
              <div class="item" v-if="Boolean(buyMultiplePackages)">
                <div class="left title">购买数量:</div>
                <van-stepper v-model="num" max='12' :disabled="payInfo.orderType == 'stopOrder'" @change="changeHandle" />
              </div>
              <div class="item acttips" v-if="Boolean(buyMultiplePackages)">
                <img src="../../assets/image/popup/icon_tip.png" alt="" />
                点加号试试，有惊喜！
              </div>
            </template>
            <div class="item" v-else>
              <div class="left title">充值金额:</div>
              <div class="right desc redColorTetx">￥{{ chargeNum }}</div>
            </div>
          </div>          
          <div class="payAction">
            <!-- <p class="payType">支付方式:</p> -->
            <van-radio-group v-model="payType">
              <van-cell-group :border="false">
                <template v-if="(userInfo.device == 'wx' || userInfo.device == 'other') &&
                  automaticRenewalDisplay == 1 && setMealObject.wechatSignContract == 1 && isAutomaticRenewal"
                >
                    <van-cell class="cellMargin"  center :border="false" title="自动续费" clickable @click="setAutomaticRenewal(true)">                   
                        <template #title>
                            <span class="custom-title">自动续费</span>                        
                            <span style="color: #999;font-size: 12px;margin-left: 8px;" @click.stop="viewAgreement()">查看协议</span>
                        </template>
                        <template #icon>
                            <img src="../../assets/image/popup/icon_wechat.png" alt="" />
                        </template>
                        <template #right-icon>
                            <van-radio name="5" :disabled="!paySelection.wx" :checked-color="tool.getThemeList(theme)" />
                        </template>
                    </van-cell>
                    <van-cell class="customCell">
                        <template #value>                    
                            <section>
                                <div style="color: #999;">亲爱的用户,充值前请认真核对卡号信息,以免发生误充。</div>
                                <div style="color: #999;">套餐到期当天自动续费,可随时取消:(1)微信:打开微信右下角进入"我"-"服务"-"钱包"-"支付设置"-"自动续费",选中已签约的项目,点击"关闭扣费服务"。</div>
                                <div style="color: blue;">确认卡号: {{ store.state.user.userInfo.virtualId || store.state.user.userInfo.iccid }}</div>
                            </section>
                        </template>
                    </van-cell>
                </template>
                <!-- 已配置自动续费的支付方式 不展示其它支付方式 -->
                <template v-if="!isAutomaticRenewal">
                    <van-cell style="margin-bottom: 26px;" class="cellMargin" center :border="false" clickable
                      @click="setPayType('0', true, (!(userMoney >= (couponMoney() || chargeMoney) || cardMoney >= (couponMoney() || chargeMoney)) && shieldRecharge == 1))"
                      v-if="isShowBalance()">
                      <template #icon>
                        <img src="../../assets/image/popup/order_icon.png" alt="" />
                      </template>
                      <template #title>
                        <div class="moneyPay">
                          <span>余额支付</span>
                          <div class="money">
                            用户/卡余额：{{ userMoney }}/{{ cardMoney }}
                          </div>
                        </div>
                      </template>
                      <template #right-icon>
                        <template
                          v-if="userMoney >= (couponMoney() || chargeMoney) || cardMoney >= (couponMoney() || chargeMoney)">
                          <van-radio name="0" :checked-color="tool.getThemeList(theme)" />
                        </template>
                        <template v-else>
                          <span v-if="shieldRecharge == 1 || userInfo.labels.includes(2) || userInfo.labels.includes(3)"
                            class="goRecharge" @click="goChargeMoney">去充值</span>
                        </template>
                      </template>
                    </van-cell>
                    <van-cell class="cellMargin wxCell" v-if="(userInfo.device == 'wx' || userInfo.device == 'other') &&
                      ((actionType == 2 && sysPayType == 0) ||
                        actionType == 2 ||
                        sysPayType == 1 ||
                        sysPayType == 4 || (sysPayType == 0 && actionType == 1 && num >= 2)
                      ) && !(userInfo.labels.includes(2) && theme == 'AnFang' && num == 1 && actionType != 2)
                      " center :border="false" title="微信支付" clickable @click="setPayType('1', paySelection.wx)">
                      <template #icon>
                        <img src="../../assets/image/popup/icon_wechat.png" alt="" />
                      </template>
                      <template #right-icon>
                        <van-radio name="1" :disabled="!paySelection.wx" :checked-color="tool.getThemeList(theme)" />
                      </template>
                    </van-cell>
                    <van-cell class="cellMargin aliCell" v-if="(userInfo.device == 'ali' || userInfo.device == 'other') &&
                      ((actionType == 2 && sysPayType == 0) ||
                        actionType == 2 ||
                        sysPayType == 3 ||
                        sysPayType == 4) && !(userInfo.labels.includes(2) && theme == 'AnFang' && num == 1 && actionType != 2)
                      " center :border="false" title="支付宝支付" clickable @click="setPayType('3', paySelection.ali)">
                      <template #icon>
                        <img src="../../assets/image/popup/icon_alipay.png" alt="" />
                      </template>
                      <template #right-icon>
                        <van-radio name="3" :disabled="!paySelection.ali" :checked-color="tool.getThemeList(theme)" />
                      </template>
                    </van-cell>
                </template>
              </van-cell-group>
            </van-radio-group>
          </div>
          <template v-if="automaticRenewalDisplay == 1 && setMealObject.wechatSignContract == 1 && isAutomaticRenewal">
            <van-button type="primary" block size="large" class="chargeBtn" @click="handleWxSignContract"
            :disabled="disabledCharge">确认支付({{
              couponMoney() || chargeMoney }}￥)元自动续费</van-button>    
          </template>
          <template v-else>
            <van-button type="primary" block size="large" class="chargeBtn" @click="judgmentPay"
                :disabled="disabledCharge">立即充值({{
                couponMoney() || chargeMoney }}￥)</van-button>
          </template>            
          <!-- '充余额后订购'版本-充值余额 -->
          <van-popup v-model:show="balanceShow" position="bottom" closeable round teleport="body">
            <div class="balance_popup">
              <div class="balance_title">充值余额</div>
              <div class="serviceBnt" v-if="[3,4].includes(Number(userInfo.agentType))" @click="onContacts()">投诉商家</div>
              <div class="balance_desc">当前余额不足请充值，充值完成后自动订购套餐</div>
              <div class="content_list">
                <ul class="package_box">
                  <li class="package_box_" v-for="(item, index) in moneyConfig" :key="index"
                    @click="paste(item, isDisable(item))" :class="[{ active: balanceChargeNum == item },]">
                    <div class="sale_price">¥{{ item }}</div>
                  </li>
                </ul>
                <van-cell>
                  <template #title>
                    <div class="customAmount">
                      <span class="custom-title">自定义金额:</span>
                      <van-field v-model="customAmount" type="digit" placeholder="请输入金额" style="width: 190px;" maxlength="11" @blur="onCustomAmount($event)" />
                    </div>
                  </template>
                </van-cell>

                <div v-if="moneyConfig.length == 0" class="empty_list">
                  暂无余额套餐包~~~
                </div>
              </div>
              <div class="payAction">
                <van-radio-group v-model="balancePayType">
                  <van-cell-group :border="false">
                    <van-cell class="cellMargin" v-if="(userInfo.device == 'wx' || userInfo.device == 'other') &&
                      ((actionType == 2 && sysPayType == 0) ||
                        actionType == 2 ||
                        sysPayType == 1 ||
                        sysPayType == 4 || (sysPayType == 0 && actionType == 1 && num >= 2)
                      )
                      " center :border="false" title="微信支付" clickable @click="setBalancePayType('1', paySelection.wx)">
                      <template #icon>
                        <img src="../../assets/image/popup/icon_wechat.png" alt="" />
                      </template>
                      <template #right-icon>
                        <van-radio name="1" :disabled="!paySelection.wx" :checked-color="tool.getThemeList(theme)" />
                      </template>
                    </van-cell>
                    <van-cell class="cellMargin" v-if="(userInfo.device == 'ali' || userInfo.device == 'other') &&
                      ((actionType == 2 && sysPayType == 0) ||
                        actionType == 2 ||
                        sysPayType == 3 ||
                        sysPayType == 4)
                      " center :border="false" title="支付宝支付" clickable
                      @click="setBalancePayType('3', paySelection.ali)">
                      <template #icon>
                        <img src="../../assets/image/popup/icon_alipay.png" alt="" />
                      </template>
                      <template #right-icon>
                        <van-radio name="3" :disabled="!paySelection.ali" :checked-color="tool.getThemeList(theme)" />
                      </template>
                    </van-cell>
                  </van-cell-group>
                </van-radio-group>
              </div>
              <van-button type="primary" block size="large" class="balanceChargeBtn" @click="balancePay"
                :disabled="disabledCharge">立即充值({{ balanceChargeNum || customAmount }}￥)</van-button>
            </div>
          </van-popup>
        </van-action-sheet>

      </slot>
    </div>

    <!-- 优惠券弹框  -->
    <coupon-dialog ref="CouponDialog" @UpdataCoupon="UpdataCoupon"></coupon-dialog>
    <template v-if="discountLockDialog === false">
      <van-dialog v-model:show="RechargePromptDialog" @cancel="onClose" :showCancelButton="false"
        :showConfirmButton="false" title="即将取消订单，你是想？" show-cancel-button>
        <div class="rechargeDialog">
          <div class="echargeDialog_bnt">
            <div class="pay" @click="RechargePromptDialogClose">继续支付</div>
          </div>
          <div class="echargeDialog_bnt">
            <div class="select" @click="rechargeGuidanceNav()">不清楚为什么要充值</div>
          </div>
          <div class="echargeDialog_bnt">
            <div class="select" @click="onClose">重选套餐</div>
          </div>
        </div>
      </van-dialog>
    </template>
    <!-- 优惠活动提醒弹窗 -->
    <van-popup v-model:show="discountLockDialog" round :style="{ padding: '30px 20px', width: '75%' }"
      :close-on-click-overlay="false">
      <div>
        <div style="font-weight: 700;">优惠活动即将结束真的要取消支付吗？</div>
        <div style="display: inline-block;flex-wrap: wrap;margin-top: 20px;align-items: center;">
          优惠活动即将在
          <van-count-down :time="setMealObject.countdown * 1000" :format="`DD天HH:mm:ss`" style="display: inline-block;">
            <template #default="timeData">
              <span class="block">{{ timeData.days >= 10 ? timeData.days : `0${timeData.days}` }}</span>
              <span class="colon">天</span>
              <span class="block">{{ timeData.hours >= 10 ? timeData.hours : `0${timeData.hours}` }}</span>
              <span class="colon">:</span>
              <span class="block">{{ timeData.minutes >= 10 ? timeData.minutes : `0${timeData.minutes}` }}</span>
              <span class="colon">:</span>
              <span class="block">{{ timeData.seconds >= 10 ? timeData.seconds : `0${timeData.seconds}` }}</span>
            </template>
          </van-count-down>
          <div style="display: inline;">之后结束,请尽快完成支付。</div>
        </div>
        <div style="display: flex;justify-content: space-between;margin-top: 30px;">
          <div style="padding:5px 30px;border: solid 1px red;color: red;border-radius: 30px;text-align: center;"
            @click="discountLockDialog = false">继续支付</div>
          <div style="padding:5px 30px;color: #ffffff;border-radius: 30px;text-align: center;background-color: red;"
            @click="closeDialog">取消支付</div>
        </div>
      </div>
    </van-popup>
    <automatic-renewal v-if="agreementShow" ref="automaticRenewalRef"/>
  </div>
</template>

<script>
import {
  defineComponent,
  toRefs,
  reactive,
  onMounted,
  computed,
  getCurrentInstance,
  nextTick,
  watch
} from 'vue'
import { useRoute } from 'vue-router'
import { setStore, getStore, removeStore } from '@/utils/store'
import { wxOrder, aliPayOrder, walletPayOrder, fetchWxSchema, minRechargeAmount } from '_API_/api.services'

import nativeCallBack from '@/utils/nativeCallBack'
import { trackSubmit, handleToLowerCase } from '@/utils/track'
import { Dialog, Button } from 'vant'
const { nativeIntercation } = nativeCallBack
export default defineComponent({
  components: {
    [Dialog.Component.name]: Dialog.Component
  },
  name: 'PopupContainer',
  props: {
    serviceInfo: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    buyMultiplePackages: {
      type: Number,
      default: 0
    },
    chargeNum: {
      type: Number,
      default: 0
    },
    setMealObject: {
      type: Object,
      default: {}
    },
    cardMoney: {
      type: Number,
      default: 0
    },
    userMoney: {
      type: Number,
      default: 0
    },
    stopPackage: {
      type: Boolean,
      default: false
    },
    rechargeData: {
      type: Object,
      default: {}
    },
    moneyConfig: {
      type: Object,
      default: []
    }
  },
  setup (prop, context) {
    const state = reactive({
      shieldRecharge: 1, // 屏蔽余额充值 0-隐藏 1-显示
      // 安防中天app内嵌web需上报支付信息
      zhongtian: false,
      theme: '', // 主题名称
      platformCode: '', // 平台code码
      num: 1, // 套餐数量
      effect: '', // 生效日期
      actionType: 1, // 1 充值套餐 2 充值余额
      show: false, // 弹框展示
      balanceShow: false, // 余额充值弹窗
      userInfo: {}, // 用户信息
      userOpenId: {}, // 用户的OpenId
      payInfo: { money: '', orderType: '', activeType: 0 }, // 支付信息
      payType: '', // 用户选择的支付方式
      balancePayType: '', // 余额支付方式
      sysPayType: '', // 系统设置的支付方式
      paySelection: {
        wx: false,
        ali: false
      }, // 过滤支付选项
      flowStatus: 'all', // 展示流量： used : 不展示 /  all ： 展示
      isShowPackageInfoTips: false, // 语音平台屏蔽 【生效时间 & 套餐信息】
      couponObj: {},
      RechargePromptDialog: false, // 充值引导
      discountLockDialog: false, // 优惠结束提示
      balanceChargeNum: null, // '充余额后订购'版本-充值多少余额?
      customAmount: null, // 余额自定义金额
      inputRechargeAmount: '', // 自定义最小输入金额
      agentType: '', // 代理类型
      agreementShow: false, // 自动续费协议面板展示
      isAutomaticRenewal: false, // 勾选自动续费 
      automaticRenewalDisplay: 0, // 微信签约支付开关  0-关闭 1-开启
    })
    // 这里的ctx  类似于vue2的this
    const { proxy: ctx } = getCurrentInstance()
    // 路由 this.$router
    const { $tool: tool, $store: store, $router: router, $http: http } = ctx
    var u = navigator.userAgent
    state.zhongtian = u.indexOf('zhongtian') > -1
    // 调用useRoute,获取页面携带的参数
    const route = useRoute()
    // 计算属性=>disabledCharge
    const disabledCharge = computed(() => {
      if (state.actionType == 1 && state.sysPayType == 1 && state.userInfo.device == 'ali') {
        return true
      }
      if (state.actionType == 1 && state.sysPayType == 3 && state.userInfo.device == 'wx') {
        return true
      }
      return false
    })
    // 套餐充值金额
    const chargeMoney = computed(() => {
      const totalAmount = ctx.chargeNum * state.num
      // 小数点 2位
      let billableAmount = totalAmount.toFixed(2)
      // 取小数点后的数值
      const floor = billableAmount.split('.')[1]
      // 截取最后的位数
      const isZero = Number(floor.slice(1))
      if (!isZero) {
        const res = billableAmount.slice(0, billableAmount.length - 1)
        billableAmount = Number(res)
      } else {
        return Number(billableAmount)
      }
      return billableAmount
    })
    // 生效时间展示
    const showEffectTime = computed(() => {                
        if (['XiaoMing', 'TianPeng', 'ZhongXun', 'AnFang'].includes(state.theme)) {
            if (['AnFang'].includes(state.theme) && state.userInfo.labels.includes(4)) {
                return false
            }
            return true
        }
        return false
    })
    watch(() => state.balanceShow, (newValue, oldValue) => {
      if (newValue === false) {
        state.balancePayType = ''
        state.balanceChargeNum = null
        state.customAmount = null
      }
    })    
    const methods = {
        viewAgreement () {
            console.log('查看协议');
            state.agreementShow = true
            ctx.$nextTick(() => {
                ctx.$refs.automaticRenewalRef.show = true
            })
        },
        // 获取微信签约支付开关        
        getWechatAutomaticRenewalDisplay(){
            return http.post(`/pay/getWechatAutomaticRenewalDisplay`).then(({ data: res }) => {
                if (res.code == 0) {
                    state.automaticRenewalDisplay = res.status
                    if (res.status == 1 && ctx.setMealObject.wechatSignContract == 1) {
                        state.payType = '5'
                        state.isAutomaticRenewal = true
                    } else {                        
                        state.payType = ''
                        state.isAutomaticRenewal = false
                    }
                    return
                }
                state.automaticRenewalDisplay = 0                
            })
        },
        // 微信自动续费 -> 发起签约
        handleWxSignContract () {
            // const totalAmount = (Number(chargeMoney.value) - Number(ctx.setMealObject.discountAmount)).toFixed(2)
            http.post(`/pay/wxSignContract`, {
                iccid:  store.state.user.userInfo.iccid,
                packageId: ctx.setMealObject.packageId,
                packagePrice: ctx.setMealObject.price,
                // packagePrice: ctx.couponMoney() || chargeMoney.value,
                signType: state.userInfo.device == 'wx' ? "JSAPI" : state.userInfo.device == 'other' ? "H5" : undefined,                
                url: 1,
                couponDistributionRecordId: ctx.couponMoney() ? ctx.setMealObject.couponDistributionRecordId : undefined,
            }).then(({ data: res }) => {
                if (res.code == 0) {
                    console.log('res:', res);
                    // location.href = 'https://wx.gtimg.com/authority_payment/renew_fail.html?errcode=paramFail'
                    location.href = res.redirectUrl
                    return
                }
                tool.toast({
                    msg: res.msg,
                    duration: 1500
                })
            })
        },
      onContacts () { // 客服
        // ctx.$parent.onContacts('confirmOrderUrc')
        location.href = 'https://ten.sobot.com/ticketclient/index.html?sysNum=2f388dd84ba745e39d7fced13097d4dd&deployId=bd4c128466f94fbbbef3b4a24fbb5c79'
      },
      getMinRechargeAmount () {
        minRechargeAmount().then(({ data }) => {
          if (data.code == 0) {
            state.inputRechargeAmount = Number(data.data || 0)
          }
        })
      },
      RechargePromptDialogClose () {
        state.RechargePromptDialog = false
        sessionStorage.setItem('isRechargeGuidance', true)
      },
      onClose () {
        state.RechargePromptDialog = false
        state.show = false
        sessionStorage.setItem('isRechargeGuidance', true)
      },
      rechargeGuidanceNav () {
        trackSubmit({ eventCode: handleToLowerCase(state.theme) + '_recharge_guide_page' })
        router.push({
          name: 'recharge-guidance',
          query: { ...ctx.rechargeData }
        })
        sessionStorage.setItem('isRechargeGuidance', true)
      },
      onCancel () {
        if (ctx.rechargeData.RECHARGE_GUIDANCE_OPEN == '1' && !sessionStorage.getItem('isRechargeGuidance') && ctx.rechargeData.RECHARGE_GUIDANCE_URL) {
          state.show = true
          state.RechargePromptDialog = true
        } else {
          state.show = false
          state.RechargePromptDialog = false
        }

        if (ctx.setMealObject.countdown) {
          state.discountLockDialog = true
          state.show = true
          state.RechargePromptDialog = false
        }
      },
      clickOverlay () {
        if (ctx.rechargeData.RECHARGE_GUIDANCE_OPEN == '1' && !sessionStorage.getItem('isRechargeGuidance') && ctx.rechargeData.RECHARGE_GUIDANCE_URL) {
          state.RechargePromptDialog = true
        } else {
          state.show = false
          state.RechargePromptDialog = false
        }

        if (ctx.setMealObject.countdown) {
          state.discountLockDialog = true
          state.show = true
          state.RechargePromptDialog = false
        }
      },
      closeDialog () {
        state.show = false
        state.discountLockDialog = false
        state.RechargePromptDialog = false
      },
      // 优惠券计算金额
      couponMoney () {
        if (state.couponObj.couponDistributionRecordId) {
          const totalAmount = (Number(chargeMoney.value) - Number(state.couponObj.discountAmount)).toFixed(2)
          return totalAmount
        }
        return ''
      },
      // 优惠券弹框
      openCoupon (obj) {
        if (!obj.couponDistributionRecordId) return
        ctx.$nextTick(() => {
          ctx.$refs.CouponDialog.init(obj, state.couponObj.couponDistributionRecordId ? state.couponObj : '',state.isAutomaticRenewal ? 2 : 1)
        })
      },
      UpdataCoupon (val) {
        console.log(JSON.stringify(val))
        state.couponObj = val || {}
      },
      /**
       * payParams: 支付参数 obj
       */
      fetchWxSchemaURL (payParams) {
        // 获取微信schema地址 （支付参数）
        fetchWxSchema(payParams).then(({ data }) => {
          if (data.code == 0) {
            return location.href = data.msg
          }
          console.log(data.msg)
        }).catch(err => {
          console.log(err)
        })
      },
      // 弹框展示
      async isShow () {
        state.num = 1
        state.effect = ''
        state.show = true
        if(state.theme == 'AnFang'){
            await ctx.getWechatAutomaticRenewalDisplay();
        }
        const totalAmount = (Number(chargeMoney.value) - Number(ctx.setMealObject.discountAmount)).toFixed(2)
        const trxamt = ctx.setMealObject.couponDistributionRecordId ? totalAmount : chargeMoney.value
        if (state.userInfo.labels.includes(3) && state.actionType != 2 && ctx.userMoney < trxamt && ctx.cardMoney < trxamt && state.theme == 'AnFang') {
          // 判断是不是建议预存，建议预存需走该弹窗流程
          nextTick(() => {
            Dialog.confirm({
              title: '温馨提示',
              confirmButtonText: '前往充值',
              confirmButtonColor: '#3061fd',
              teleport: '#app',
              message:
                '当前卡片余额不足以订购套餐，是否前往充值话费'
            }).then(() => {
              state.balanceShow = true
            }).catch(() => {
            })
          })
        }
        if (ctx.setMealObject.couponDistributionRecordId) {
          state.couponObj = Object.assign({}, state.couponObj, {
            couponDistributionRecordId: ctx.setMealObject.couponDistributionRecordId,
            postCouponPrice: ctx.setMealObject.postCouponPrice,
            discountAmount: ctx.setMealObject.discountAmount
          })
        } else {
          state.couponObj = {}
        }
        ctx.setPaySelection()
      },
      isShowBalance () { // 是否显示余额支付
        if ((state.userInfo.labels.includes(2) && state.actionType != 2 && state.theme != 'AnFAng') ||
          (state.userInfo.labels.includes(3) && state.actionType != 2 && state.theme != 'AnFAng') ||
          (state.actionType == 1 && state.sysPayType == 0) ||
          (state.actionType == 1 && (state.sysPayType == 4 && (ctx.cardMoney >= ctx.chargeNum || state.shieldRecharge == 1)))) {
          return true
        } else {
          return false
        }
      },
      // 设置余额充值 支付方式
      setBalancePayType (type, action) {
        if (!action) return
        state.balancePayType = type
      },
      balancePay () { // 充值余额 并自动购买套餐
        if (!state.balanceChargeNum && !state.customAmount) {
          tool.toast({
            msg: '请选择余额',
            duration: 1500
          })
          return
        }
        // 输入金额不能低于配置金额
        if (state.customAmount && Number(state.inputRechargeAmount) > Number(state.customAmount)) {
          tool.toast({
            msg: '充值金额不能低于' + state.inputRechargeAmount + '元',
            duration: 1500
          })
          return
        }
        if (!state.balancePayType) {
          tool.toast({
            msg: '请选择支付方式',
            duration: 1500
          })
          return
        }
        state.payType = state.balancePayType
        ctx.Pay()
      },
      isDisable (item) { // 判断充值余额是否小于此次 订单、优惠后的实付金额
        const newNum = state.couponObj.couponDistributionRecordId ? ctx.couponMoney() : chargeMoney.value
        if (Number(item) < Number((newNum - ctx.cardMoney).toFixed(2))) {
          return true
        } else {
          return false
        }
      },
      onCustomAmount (e) {
        // 输入金额不足支付套餐金额
        if (state.customAmount && ctx.isDisable(state.customAmount)) {
          tool.toast({
            msg: '选择余额不足，将无法完成自动订购套餐',
            duration: 1500
          })
        }
        state.balanceChargeNum = null
      },
      // 选中充值包的方法
      paste (item, isDisable) {
        state.customAmount = null
        state.balanceChargeNum = Number(item)
      },
      // 购买数量
      changeHandle (value) {
        state.num = value
        http.post('/card/queryPackageEffect', {
          card: store.state.user.userInfo.iccid,
          packagePrice: ctx.setMealObject,
          num: value
        }).then(({ data: res }) => {
          if (res.code == 0) {
            state.effect = res.effect
          }
        })
      },
      setPaySelection () {
        state.payType = ''
        state.sysPayType = store.state.user.userInfo.payType
        // 如果系统配置只有一种支付方式，则默认勾选该方式
        if (state.sysPayType !== 4) {
          state.payType = String(state.sysPayType)
        }
        // 余额充值  微信 支付宝环境下默认勾选
        if (state.sysPayType == 1 && state.userInfo.device == 'wx') {
          state.payType = '1'
        }
        if (state.sysPayType == 1 && state.userInfo.device == 'ali') {
          state.payType = '3'
        }
        if (state.sysPayType == 3 && state.userInfo.device == 'ali') {
          state.payType = '3'
        }
        if (state.sysPayType == 3 && state.userInfo.device == 'wx') {
          state.payType = '1'
        }
        // 系统支付为微信支付 或 支付宝支付 且在浏览器环境下进行余额充值 不勾选
        if (
          (state.sysPayType == 1 || state.sysPayType == 3) &&
          state.userInfo.device == 'other'
        ) {
          state.payType = ''
        }
        // 系统支付为微信支付 或 支付宝支付  且 是充值套餐 且在浏览器环境下 则默认勾选
        if (
          (state.sysPayType == 1 || state.sysPayType == 3) &&
          state.actionType == 1 &&
          state.userInfo.device == 'other'
        ) {
          state.payType = String(state.sysPayType)
        }
        // 系统支付为余额支付 或 微信支付 或 全部  且 是余额充值 且在微信环境下 则默认勾选
        if (
          (state.sysPayType == 0 ||
            state.sysPayType == 1 ||
            state.sysPayType == 4) &&
          state.actionType == 2 &&
          state.userInfo.device == 'wx'
        ) {
          state.payType = '1'
        }
        // 系统支付为余额支付 或 支付宝支付 或 全部  且 是余额充值 且在支付宝环境下 则默认勾选
        if (
          (state.sysPayType == 0 ||
            state.sysPayType == 3 ||
            state.sysPayType == 4) &&
          state.actionType == 2 &&
          state.userInfo.device == 'ali'
        ) {
          state.payType = '3'
        }
        // 余额支付显示的前提下 余额大于等于 优惠金额套餐，并且当前不是"套餐页面-余额充值"触发
        const trxamt = state.couponObj.couponDistributionRecordId ? ctx.couponMoney() : chargeMoney.value
        if (ctx.isShowBalance() && (ctx.userMoney >= trxamt || ctx.cardMoney >= trxamt) && state.actionType != 2) {
          state.payType = '0'
        }
        // 安防平台 且 系统支付为【微信支付 或 全部支付】 且 【微信环境 或 浏览器环境】 且 【不是微信自动续费】 则默认勾选微信支付
        if (state.theme == 'AnFang' && (state.sysPayType == 1 || state.sysPayType == 4) && (state.userInfo.device == 'wx' || state.userInfo.device == 'other') && !state.isAutomaticRenewal) {
          // 如果是强制预存卡 且套餐数量只有一个 默认选余额支付
          state.payType = (state.userInfo.labels.includes(2) && state.num == 1) ? '0' : '1';
        }
        const conf = {
          wx: true,
          ali: true
        }
        if (state.sysPayType == 0 && state.userInfo.device == 'wx') {
          conf.ali = false
        }
        if (
          (state.sysPayType == 1 || state.sysPayType == 4) &&
          state.userInfo.device == 'ali'
        ) {
          conf.wx = false
        }
        if (
          (state.sysPayType == 3 || state.sysPayType == 4) &&
          state.userInfo.device == 'wx'
        ) {
          conf.ali = false
        }
        state.paySelection = conf
      },
      /** 去充值
       * @param {type} active
       * 套餐充值的余额充值通过子传父并携带值
       * 停机保号的余额充值通过路由携带参数跳转
       */
      goChargeMoney () {
        if ((state.userInfo.labels.includes(3) || state.userInfo.labels.includes(2)) && state.theme == 'AnFang') {
          state.balanceShow = true
          return
        }
        switch (state.payInfo.orderType) {
          case 'ordinaryOrder':
            state.show = false
            context.emit('selectActive', {
              active: 1,
              typeActive: state.payInfo.activeType
            })
            break
          case 'stopOrder':
            router.push({
              name: 'personal-packageRecharge',
              query: {
                active: 1
              }
            })
            break
          case 'AnFangOrder':
            router.push({
              name: 'personal-packageRecharge',
              query: {
                type: 0,
                active: 1
              }
            })
            break
          default:
            break
        }
      },
      // 自动续费
      setAutomaticRenewal(bool){
        state.isAutomaticRenewal = bool             
        if (ctx.setMealObject.couponDistributionRecordId){
            ctx.$nextTick(() => {
              ctx.$refs.CouponDialog.getSelectCoupon(ctx.setMealObject,2)
              ctx.$refs.CouponDialog.autoSelect = true              
            })
        }
      },  
      // 设置支付方式
      setPayType (type, action, disable) {
        state.isAutomaticRenewal = false
        if (ctx.setMealObject.couponDistributionRecordId){
            ctx.$nextTick(() => {
              ctx.$refs.CouponDialog.getSelectCoupon(ctx.setMealObject,1)              
              ctx.$refs.CouponDialog.autoSelect = true
            })
        }
        if (disable) return
        if (!action) return
        state.payType = type
      },
      judgmentPay () {
        // 如果卡片带【强制预存类】标签，并且未选择支付方式，
        // 并且卡片余额＜当前套餐金额*数量-优惠金额，
        // 并且用户余额＜当前套餐金额*数量-优惠金额：点击直接弹出充值余额弹窗；（套餐购买数量＞1时支付方式会显示微信和支付宝，选择微信或支付宝去充值不弹充值余额弹窗）
        // couponMoney() || chargeMoney
        if (state.userInfo.labels.includes(2) &&
          (ctx.cardMoney < (state.couponObj.couponDistributionRecordId ? ctx.couponMoney() : chargeMoney.value)) &&
          (ctx.userMoney < (state.couponObj.couponDistributionRecordId ? ctx.couponMoney() : chargeMoney.value)) &&
          state.theme == 'AnFang' &&
          state.actionType != 2) {
          state.balanceShow = true
          return
        }
        ctx.Pay()
      },
      // 支付
      Pay () {
        trackSubmit({
          eventCode: handleToLowerCase(state.theme) + '_recharge_confirm_click',
          payType: state.payType,
          cardBalance: ctx.cardMoney
        })
        // 若系统支付仅开放 余额支付 且 是订购套餐 且数量大于等于2 且 用户选择微信支付
        if (state.sysPayType == 0 && state.actionType == 1 && state.num >= 2 && state.payType == '1') {
          return ctx.getWxPayInfo(state.actionType == 1 ? 2 : 1)
        }
        if (
          state.sysPayType == 0 &&
          state.actionType == 1 &&
          (state.userInfo.device == 'wx' ||
            state.userInfo.device == 'ali' ||
            state.userInfo.device == 'other') &&
          (!ctx.cardMoney && !ctx.userMoney)
        ) {
          return tool.toast({
            msg: '请先充值余额，然后充值套餐',
            duration: 1500
          })
        }
        console.log(`state.payType--${state.payType}---state.balanceShow: ${state.balanceShow}---state.actionType: ${state.actionType}`);
        if (
          (!state.payType && state.actionType == 1) ||
          (state.payType == '0' && state.actionType == 2) || 
          // 如果是强制预存卡，支付选择不是余额支付，且不是打开充值弹窗  且是购买套餐数量为1的情况下 需提示用户选择余额支付
          (state.userInfo.labels.includes(2) && state.payType != '0' && !state.balanceShow && state.actionType == 1 && state.num == 1)
        ) {
          return tool.toast({
            msg: '请选择支付方式',
            duration: 1500
          })
        }
        if ((state.payType == '0' && state.actionType == 1)) {
          ctx.walletPayOrderFn()
        }
        if (state.payType == '1') {
          ctx.getWxPayInfo(state.actionType == 1 ? 2 : 1)
        }
        if (state.payType == '3') {
          ctx.getAliPayInfo(state.actionType == 1 ? 2 : 1)
        }
      },
      // 微信支付
      getWxPayInfo (type) {
        state.userOpenId = getStore({ name: 'userOpenId' })
        if (state.theme !== 'AnFang') {
          methods.judgeOpenId()
        }
        /**
         * 参数说明
         * @param {*} type   1:微信充值余额   2:微信支付下单
         * @param {*} payType 1: 公众号支付  2: 小程序支付
         * @param {*} config   接口所需的参数
         */
        /**
         * 参数说明
         * @param {*} paymentWxType
         * 0-微信 1-通联 2-首赋 3-杉德支付
        */
        const paymentWxType = state.userInfo.paymentWxType
        console.log('paymentWxType:', paymentWxType)
        // 选择的金额是否足够买套餐 true：够  false：不够
        let isMoenyBuyPackage = state.balanceChargeNum ? !ctx.isDisable(state.balanceChargeNum) : !ctx.isDisable(state.customAmount)
        if (!state.balanceChargeNum && !state.customAmount) {
          isMoenyBuyPackage = true
        }
        const config = {
          payType: 1,
          platformCode: state.platformCode,
          iccid: state.userInfo.iccid,
          trxamt: state.couponObj.couponDistributionRecordId ? ctx.couponMoney() : chargeMoney.value,
          orderAmount: chargeMoney.value,
          couponDistributionRecordId: state.couponObj.couponDistributionRecordId || '',
          body: '微信测试充值',
          openid: state.userOpenId ? state.userOpenId.openId : null,
          appId: state.userInfo.wxPayAppid,
          num: state.num,
          url: paymentWxType == 1 ? window.location.origin + '/pay-result' : window.location.origin,
          tradeType: state.userInfo.device == 'wx' ? 'JSAPI' : 'MWEB'
        }
        let rechargeParams = JSON.parse(JSON.stringify(config)) // 深拷贝默认参数
        if (!isMoenyBuyPackage && state.balanceShow) { // 如果充值的金额不足以支付
          rechargeParams = Object.assign({}, rechargeParams, {
            couponDistributionRecordId: '',
            trxamt: state.balanceChargeNum || state.customAmount,
            orderAmount: state.balanceChargeNum || state.customAmount
          })
        }
        if (state.balanceShow) {
          config.userRechargeAmount = state.balanceChargeNum || state.customAmount
        }
        if (type == 2) {
          config.packageId = ctx.setMealObject.packageId
          config.trxamt = state.couponObj.couponDistributionRecordId ? ctx.couponMoney() : chargeMoney.value
        }
        if (state.userInfo.device === 'other' && paymentWxType == 1) { // 走通联,小程序调起支付
          return methods.fetchWxSchemaURL(config)
        }
        if (state.userInfo.device === 'wx' && paymentWxType == 0) { // 走原生微信jsapi调起支付
          const tongLianInfo = {
            isTongLian: false
          }
          store.commit('Set_TongLian_Info', tongLianInfo)
        }
        if (state.userInfo.device === 'wx' && paymentWxType == 1) { // 走通联,微信jsapi调起支付
          const tongLianInfo = {
            isTongLian: true,
            card: state.userInfo.virtualId || state.userInfo.iccid
          }
          store.commit('Set_TongLian_Info', tongLianInfo)
        }
        return new Promise((resolve, reject) => {
          // 微信支付=>余额充值
          const params = isMoenyBuyPackage ? config : rechargeParams
          // 如果是推荐套餐 - 则 加推荐套餐订单的字段
          Object.assign(params, {
            recommendedOrder: ctx.setMealObject.recommendedOrder,
          })
          wxOrder(params)
            .then(async ({ data: res }) => {
              console.log('wxpay', res)
              if (res.code == 333 || res.code == 444) {
                removeStore({ name: 'userOpenId', type: 'session' })
                location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${state.userInfo.appid}&redirect_uri=${window.location.origin}/transfer-page&response_type=code&scope=snsapi_base&state=STATE&connect_redirect=1#wechat_redirect`
                return
              }
              if (res.code == 0) {
                switch (state.userInfo.device) {
                  case 'wx':
                    // state.payInfo = res.data;
                    Object.assign(state.payInfo, res.data)
                    ctx.wxPay()
                    break
                  case 'other':
                    // 存储isCharge到sessionStorage
                    setStore({
                      name: 'isCharge',
                      content: '1',
                      type: 'session'
                    })
                    if (paymentWxType == 3) { // 走杉德
                      window.location.href = `${res.data.url}${tool.params(res.data)}` // 杉德
                    } else {
                      try {
                        // 安防中天嵌入web 提交支付参数给app
                        if (state.zhongtian && type == 2) {
                          await nativeIntercation('sendPayInfo', Object.assign(res.extendOrderParam, {
                            payType: state.payType,
                            amount: config.trxamt,
                            timeStamp: new Date().getTime()
                          }))
                        }
                      } catch (error) {
                        console.log('传递支付参数异常')
                      }
                      trackSubmit({
                        eventCode: handleToLowerCase(state.theme) + '_recharge_pay_show',
                        payAmount: chargeMoney.value
                      })
                      window.location.href = res.data.mweb_url // 原有
                    }
                    break
                  default:
                    break
                }
              } else {
                tool.alert(
                  {
                    type: '提示',
                    msg: res.msg
                  },
                  () => { }
                )
              }
            })
            .catch(error => {
              reject(error)
            })
        })
      },
      // 支付宝支付
      getAliPayInfo (type) {
        /**
         * 参数说明
         * @param {*} type   1:支付宝充值余额   2:支付宝支付下单
         * @param {*} config   接口所需的参数
         */
        /**
         * 参数说明
         * @param {*} paymentAliType
         * 1-支付宝 2-通联 3-杉德支付
        */
        const paymentAliType = state.userInfo.paymentAliType
        console.log('paymentAliType:', paymentAliType)
        const config = {
          iccid: state.userInfo.iccid,
          trxamt: state.couponObj.couponDistributionRecordId ? ctx.couponMoney() : chargeMoney.value,
          orderAmount: chargeMoney.value,
          couponDistributionRecordId: state.couponObj.couponDistributionRecordId || '',
          body: '支付宝测试充值',
          openid: state.userOpenId ? state.userOpenId.openId : null,
          appId: state.userInfo.aliAppId,
          url: window.location.origin,
          num: state.num,
          tradeType: state.userInfo.device == 'ali' ? '0' : '1'
        }
        // 选择的金额是否足够买套餐 true：够  false：不够
        let isMoenyBuyPackage = state.balanceChargeNum ? !ctx.isDisable(state.balanceChargeNum) : !ctx.isDisable(state.customAmount)
        if (!state.balanceChargeNum && !state.customAmount) {
          isMoenyBuyPackage = true
        }
        let rechargeParams = JSON.parse(JSON.stringify(config)) // 深拷贝默认参数
        if (!isMoenyBuyPackage && state.balanceShow) { // 如果充值的金额不足以支付
          rechargeParams = Object.assign({}, rechargeParams, {
            couponDistributionRecordId: '',
            trxamt: state.balanceChargeNum || state.customAmount,
            orderAmount: state.balanceChargeNum || state.customAmount
          })
        }
        if (state.balanceShow) {
          config.userRechargeAmount = state.balanceChargeNum || state.customAmount
        }
        if (type == 2) {
          config.packageId = ctx.setMealObject.packageId
          config.trxamt = state.couponObj.couponDistributionRecordId ? ctx.couponMoney() : chargeMoney.value
        }
        return new Promise((resolve, reject) => {
          // 支付宝支付=>余额充值
          const params = isMoenyBuyPackage ? config : rechargeParams
          // 如果是推荐套餐 - 则 加推荐套餐订单的字段
          Object.assign(params, {
            recommendedOrder: ctx.setMealObject.recommendedOrder,
          })
          aliPayOrder(params)
            .then(({ data: res }) => {
              if (res.code == 0) {
                if (state.userInfo.device === 'other' && paymentAliType == 2) { // 走通联
                  return location.href = res.data.payUrl
                }
                if (state.userInfo.device === 'ali' && paymentAliType == 2) { // 走通联
                  return ctx.aliPayTongLianFn(res.data)
                }
                if (paymentAliType == 3) { // 走杉德
                  return ctx.aliPaySDFn(res.data)
                }
                ctx.aliPayFn(res.data.body, res, type, config)
              } else {
                tool.alert(
                  {
                    type: '提示',
                    msg: res.msg
                  },
                  () => { }
                )
              }
            })
            .catch(error => {
              reject(error)
            })
        })
      },
      // JSAPI调起微信支付
      wxPay () {
        trackSubmit({
          eventCode: handleToLowerCase(state.theme) + '_recharge_pay_show',
          payAmount: chargeMoney.value
        })
        if (typeof WeixinJSBridge === 'undefined') {
          if (document.addEventListener) {
            document.addEventListener(
              'WeixinJSBridgeReady',
              ctx.onBridgeReady,
              false
            )
          } else if (document.attachEvent) {
            document.attachEvent('WeixinJSBridgeReady', ctx.onBridgeReady)
            document.attachEvent('onWeixinJSBridgeReady', ctx.onBridgeReady)
          }
        } else {
          ctx.onBridgeReady()
        }
      },
      // JSAPI调起微信支付的事件函数
      onBridgeReady () {
        WeixinJSBridge.invoke(
          'getBrandWCPayRequest',
          {
            appId: state.payInfo.appId,
            timeStamp: state.payInfo.timeStamp,
            nonceStr: state.payInfo.nonceStr,
            package: state.payInfo.package,
            signType: state.payInfo.signType,
            paySign: state.payInfo.paySign
          },
          function (res) {
            switch (res.err_msg) {
              case 'get_brand_wcpay_request:ok':
                if (state.actionType == 1) {
                  ctx.handlePayResult()
                }
                if (state.actionType == 2) {
                  ctx.handlePayResult()
                }

                break
              case 'get_brand_wcpay_request:cancel':
                state.show = false
                tool.toast(
                  {
                    msg: '取消充值',
                    duration: 1500
                  },
                  () => { }
                )
                break
              case 'get_brand_wcpay_request:fail':
                tool.toast(
                  {
                    msg: '充值失败',
                    duration: 1500
                  },
                  () => { }
                )
                break
              default:
                break
            }
          }
        )
      },
      // alipayjsapi调起支付宝支付函数
      async aliPayFn (params, res, type, config) {
        console.log('wxpay', res)
        trackSubmit({
          eventCode: handleToLowerCase(state.theme) + '_recharge_pay_show',
          payAmount: chargeMoney.value
        })
        switch (state.userInfo.device) {
          case 'ali':
            ap.tradePay(
              {
                orderStr: params
              },
              res => {
                const code = Number(res.resultCode)
                switch (code) {
                  case 9000:
                    if (state.actionType == 1) {
                      ctx.handlePayResult()
                    }
                    if (state.actionType == 2) {
                      ctx.handlePayResult()
                    }
                    break
                  case 800:
                    tool.toast(
                      {
                        msg: '正在处理中，请等待系统审核',
                        duration: 1500
                      },
                      () => {
                        router.push({
                          name: 'personal'
                        })
                      }
                    )
                    break
                  default:
                    tool.toast(
                      {
                        msg: '充值失败',
                        duration: 1500
                      },
                      () => { }
                    )
                    break
                }
              }
            )
            break
          case 'other':
            // 存储isCharge到sessionStorage
            setStore({
              name: 'isCharge',
              content: '1',
              type: 'session'
            })
            try {
              if (state.zhongtian && type == 2) {
                await nativeIntercation('sendPayInfo', Object.assign(res.extendOrderParam, {
                  payType: state.payType,
                  amount: config.trxamt,
                  timeStamp: new Date().getTime()
                }))
              }
            } catch (error) {
              console.log('传递支付参数异常')
            }
            const body = document.querySelector('body') // 原有支付
            const dom = new DOMParser().parseFromString(params, 'text/html')
            body.appendChild(dom.forms[0])
            document.forms[0].submit()
            break
          default:
            break
        }
      },
      // alipayjsapiSD调起支付宝支付函数
      aliPaySDFn (data) {
        switch (state.userInfo.device) {
          case 'ali':
            ap.tradePay(
              {
                tradeNO: data.tradeNO
              },
              res => {
                const code = Number(res.resultCode)
                switch (code) {
                  case 9000:
                    if (state.actionType == 1) {
                      ctx.handlePayResult()
                    }
                    if (state.actionType == 2) {
                      ctx.handlePayResult()
                    }
                    break
                  case 800:
                    tool.toast(
                      {
                        msg: '正在处理中，请等待系统审核',
                        duration: 1500
                      },
                      () => {
                        router.push({
                          name: 'personal'
                        })
                      }
                    )
                    break
                  default:
                    tool.toast(
                      {
                        msg: '充值失败',
                        duration: 1500
                      },
                      () => { }
                    )
                    break
                }
              }
            )
            break
          case 'other':
            // 存储isCharge到sessionStorage
            setStore({
              name: 'isCharge',
              content: '1',
              type: 'session'
            })
            window.location.href = `${data.payUrl}${tool.params(data)}` // 云商通
            // window.location.href = `${data.url}${tool.params(data)}`; // 杉德
            break
          default:
            break
        }
      },
      handlePayResult () {
        const obj = {
          card: state.userInfo.virtualId || state.userInfo.iccid,
          money: state.couponObj.couponDistributionRecordId ? ctx.couponMoney() : chargeMoney.value,
          packageName: ctx.setMealObject.packageName || '',
          desc: `${ctx.setMealObject.openManualRenewal == 1 ? '流量到账可能会存在延迟，工作日时间09:00-17:00流量生效时间预计为6小时内，其他时间预计为次日到账。' : '系统存在一定的延迟，将会在30分钟左右到帐如有问题建议您先智能诊断或者联系客服处理'}`,
          actionType: state.actionType,
          orderType: state.payInfo.orderType,
          activeType: state.payInfo.activeType // 充值套餐选中类型
        }
        const isMoenyBuyPackage = state.balanceChargeNum ? !ctx.isDisable(state.balanceChargeNum) : !ctx.isDisable(state.customAmount)
        if (state.balanceShow) {
          obj.userRechargeAmount = state.balanceChargeNum || state.customAmount
        }
        if (!isMoenyBuyPackage && state.balanceShow) { // 跳转余额充值成功页
          obj.actionType = 2
          obj.packageName = ''
        }
        router.push({
          name: 'pay-result',
          query: obj
        })
      },
      // aliPayTongLianFn调起支付宝支付函数
      aliPayTongLianFn (data) {
        console.log('aliPayTongLianFn--data:', data)
        ap.tradePay(
          {
            tradeNO: data.tradeNO
          },
          res => {
            const code = Number(res.resultCode)
            switch (code) {
              case 9000:
                if (state.actionType == 1) {
                  ctx.handlePayResult()
                }
                if (state.actionType == 2) {
                  ctx.handlePayResult()
                }
                break
              case 800:
                tool.toast(
                  {
                    msg: '正在处理中，请等待系统审核',
                    duration: 1500
                  },
                  () => {
                    router.push({
                      name: 'personal'
                    })
                  }
                )
                break
              default:
                tool.toast(
                  {
                    msg: '充值失败',
                    duration: 1500
                  },
                  () => { }
                )
                break
            }
          }
        )
      },
      // 余额支付
      walletPayOrderFn () {
        trackSubmit({
          eventCode: handleToLowerCase(state.theme) + '_recharge_pay_show',
          payAmount: chargeMoney.value
        })
        const config = {
          iccid: state.userInfo.iccid,
          recommendedOrder: ctx.setMealObject.recommendedOrder,
          packageId: ctx.setMealObject.packageId,
          packageName: ctx.setMealObject.packageName,
          num: state.num,
          amount: state.couponObj.couponDistributionRecordId ? ctx.couponMoney() : chargeMoney.value,
          orderAmount: chargeMoney.value,
          couponDistributionRecordId: state.couponObj.couponDistributionRecordId || ''
        }
        walletPayOrder(config).then(({ data }) => {
          if (data.code == 0) {
            if (state.actionType == 1) {
              ctx.handlePayResult()
            }
            if (state.actionType == 2) {
              ctx.handlePayResult()
            }

            return
          }
          if (data.code == 1004) {
            return tool.alert(
              {
                title: '提示',
                msg: '余额不足，请充值'
              },
              () => {
                ctx.goChargeMoney()
              }
            )
          }

          tool.alert({
            title: '提示',
            msg: data.msg
          })
        })
      },
      judgeOpenId () {
        if (
          (state.userOpenId === undefined || state.userOpenId.openId === '0' || state.userInfo.isPackage) &&
          state.userInfo.device === 'wx'
        ) {
          setStore({
            name: 'router',
            content:
              route.query.type == 0
                ? 'personal-packageRecharge'
                : 'stop-recharge',
            type: 'session'
          })
          location.replace(
            `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${state.userInfo.appid}&redirect_uri=${window.location.origin}/transfer-page&response_type=code&scope=snsapi_base&state=STATE&connect_redirect=1#wechat_redirect`
          )
        }
      },
      judgeAliOpenId () {
        if (
          (state.userOpenId === undefined || state.userOpenId.openId === '0') &&
          state.userInfo.device === 'ali' && state.userInfo.aliAppId
        ) {
          setStore({
            name: 'router',
            content:
              route.query.type == 0
                ? 'personal-packageRecharge'
                : 'stop-recharge',
            type: 'session'
          })
          location.replace(
            `https://openauth.alipay.com/oauth2/publicAppAuthorize.htm?app_id=${state.userInfo.aliAppId}&scope=auth_base&redirect_uri=${window.location.origin}/transfer-page`
          )
        }
      }
    }
    onMounted(() => {
      state.shieldRecharge = getStore({ name: 'isCash' })
      store.dispatch('SetUserInfo', { isConfigAppid: false })
      state.flowStatus = window.SITE_CONFIG.flowStatus || 'all'
      // 语音平台屏蔽 【生效时间 & 套餐信息】
      state.isShowPackageInfoTips = window.SITE_CONFIG.isShowPackageInfoTips || false
      // 获取主题名称
      state.theme = store.state.common.theme
      // 获取平台code 码
      state.platformCode = store.state.common.platformCode
      // 获取用户信息
      state.userInfo = store.state.user.userInfo
      // 使用sessionStorage获取存储的openId信息
      state.userOpenId = getStore({ name: 'userOpenId' })
      if (state.theme !== 'AnFang') {
        // 判断是否有openId
        methods.judgeOpenId()
        // 判断是否有AliopenId
        methods.judgeAliOpenId()
      }
      methods.getMinRechargeAmount()
      // h5支付结束
      const isCharge =
        getStore({
          name: 'isCharge'
        }) || '2'
      if (isCharge === '1') {
        tool.confirm(
          {
            title: '提示',
            msg: '充值结果系统处理中'
          },
          () => {
            if (state.theme == 'AnFang') return
            window.history.back()
          }
        )
        removeStore({ name: 'isCharge', type: 'session' })
      }
    })
    return {
      ...toRefs(state),
      ...methods,
      tool,
      store,
      disabledCharge,
      chargeMoney,
      showEffectTime
    }
  }
})
</script>
<style lang="scss" scoped>
@import "@/assets/style/mixin.scss";
@import "@/style/theme/index";

.packageVoice {
  text-decoration: none;
}

// 投诉按钮
.serviceBnt{
  position: absolute;
  top: 26px;
  right: 126px;
  color: #2196F3;
  font-size: 32px;
  text-decoration: underline;
}

.popupBox {
  .tips {
    color: #FF9966;
    padding: 20px 3%;
  }

  .packageInfo {
    width: 94%;
    margin: 20px auto;

    .item {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding: 5px 0;
      font-size: 28px;

      .left {
        width: 20%;
        color: #999;
      }

      .right {
        text-align: right;
        color: #333;
        max-width: 80%;
        overflow-x: auto;
        white-space: nowrap;
      }

      .desc_remark {
        color: #777;
        font-size: 28px;
      }

      .colorTetx {
        color: #f14153;
      }

      .colorTetx_ {
        @include font_color("font_color1");
      }

      .redColorTetx {
        font-weight: bold;
        color: #f14153;
      }

      &.acttips {
        background: url('../../assets/image/popup/tips.png');
        background-size: 345px 84px;
        height: 84px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #2196F3;

        img {
          width: 36px;
          margin-right: 6px;
        }
      }
    }
  }

  .payAction {
    width: 94%;
    padding: 20px 0;
    margin: 0 auto 52px;
    border-top: 1px solid #ececec;

    .payType {
      font-size: 24px;
      padding: 12px;
    }

    /deep/ .van-cell {
      padding: 5px;

      .van-cell__title {
        color: #222;
      }

      img {
        width: 48px;
        margin-right: 5px;
      }

      .goRecharge {
        padding: 3px 15px;
        border-radius: 1.5em;
        color: #fff;
        @include background_color("background_color1");
      }

      .moneyPay {
        display: flex;
        align-items: center;

        .money {
          margin-left: 10px;
          font-size: 24px;
          @include font_color("font_color1");
        }
      }
    }
  }

  .chargeBtn {
    width: 95%;
    height: 84px;
    @include background_color("background_color1");
    border: none;
    box-shadow: 0px 8px 16px 0px #bce1ff;
    border-radius: 20px;
    // margin: 30px auto;
    position: fixed;
    bottom: 20px;
    left: 2%;
  }
  .balanceChargeBtn {
    width: 690px;
    height: 84px;
    @include background_color("background_color1");
    border: none;
    box-shadow: 0px 8px 16px 0px #bce1ff;
    border-radius: 20px;
    margin: 30px auto;
  }
}

.cellMargin {
  margin: 24px 0px;
}

.wxCell,.aliCell{
    position: relative;
    bottom: 20px;
}
.customCell{
    border: 1px solid #F2A15C;
    padding: 24px !important;
    margin-bottom: 70px;
}

.rechargeDialog {

  .echargeDialog_bnt {

    text-align: center;
    margin: 30px 0;
    color: #ffffff;

    div {
      width: 300px;
    }

    .pay {
      padding: 12px 48px;
      background: #2196F3;
      display: inline-block;
      border-radius: 20px;
    }

    .select {
      border: 1px solid #2196F3;
      color: #2196F3;
      padding: 12px 48px;
      display: inline-block;
      border-radius: 20px;
    }
  }

  .echargeDialog_bnt:active {
    opacity: 0.7;
  }

  .discountLock-box {
    padding: 20px 20px;
    width: 80%;

    .discountLock-title {
      font-size: 36px;
      font-weight: 700 !important;
    }

    .discountLock-content {
      display: flex;
    }

    .discountLock-bnt {
      display: flex;
      justify-content: space-between;

    }
  }

}

.colon {
  display: inline-block;
  margin: 0 4px;
  color: red;
}

.block {
  display: inline-block;
  color: #fff;
  text-align: center;
  background-color: red;
  padding: 0px 8px;
  border-radius: 12px;
}

.balance_popup {
  .balance_title {
    font-weight: 700;
    // font-size: 36px;
    line-height: 108px;
    text-align: center;
  }

  .balance_desc {
    // width: 92%;
    margin: 0px 30px;
    line-height: 100px;
    border-radius: 20px;
    background: #FFF3E9;
    color: #FF763D;
    padding-left: 20px;
  }

  .payAction {
    width: 94%;
    padding: 20px 0;
    margin: auto;
    border-top: none;

    .payType {
      font-size: 24px;
      padding: 12px;
    }

    /deep/ .van-cell {
      padding: 5px;

      .van-cell__title {
        color: #222;
      }

      img {
        width: 48px;
        margin-right: 5px;
      }

      .goRecharge {
        padding: 3px 15px;
        border-radius: 1.5em;
        color: #fff;
        @include background_color("background_color1");
      }

      .moneyPay {
        display: flex;
        align-items: center;

        .money {
          margin-left: 10px;
          font-size: 24px;
          @include font_color("font_color1");
        }
      }
    }
  }

  .content_list {
    // height: calc(100vh - 190px);
    overflow-y: auto;

    .empty_list {
      width: 590px;
      height: auto;
      background: #ffffff;
      border-radius: 20px;
      margin: 20px auto;
      font-size: 26px;
      color: #b2b2b2;
      padding: 40px;
      text-align: center;
    }

    .package_box {
      width: 710px;
      display: flex;
      text-align: left;
      margin: 20px auto 0px;
      flex-wrap: wrap;

      .package_box_ {
        width: 210px;
        height: 142px;
        background: #ffffff;
        border-radius: 20px;
        // @include border_color("border_color1");
        border: 2px solid #2196f3;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        margin: 10px 10px 0px;

        .sale_price {
          font: {
            size: 36px;
            weight: 500;
          }

          color: #333333;
        }

        .show_price {
          font: {
            size: 26px;
            weight: 400;
          }

          color: #aaaaaa;
        }
      }

      .active {
        background: rgba(33, 150, 243, 0.1);
        border-radius: 20px;
        @include border_color("border_color1");
        border: 2px solid #2196f3;
      }

      .balanceDisable {
        // background: #cccccc;
        border: solid 2px #A1A1A1;
        color: #A1A1A1;

        .sale_price {
          color: #A1A1A1;
        }
      }
    }

    .customAmount {
      display: flex;
      align-items: center;
      border-bottom: solid 1px #ececec;
    }
  }
}
</style>

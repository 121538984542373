import { createRouter, createWebHistory } from 'vue-router'
import { getStore } from '@/utils/store'
import { Dialog } from 'vant'
import store from '../store'
import NProgress from 'nprogress'
import '_ASSETS_/css/nprogress.css'

NProgress.configure({
  easing: 'ease',
  speed: 500,
  showSpinner: false,
  trickleSpeed: 200,
  minimum: 0.3
})
// 获取客服URL
const kefu_URL = window.SITE_CONFIG.kefu_URL
// 是否企微客服
const qiWei_kefu = window.SITE_CONFIG.qiWei_kefu || false

const keFuHandle = (iccid) => {
  // 跳转企微客服
  kefu_URL && (location.href = kefu_URL)
  // 跳转智齿客服
  !qiWei_kefu &&
    (location.href = `${kefu_URL}&params=${JSON.stringify({
      iccid
    })}&customer_fields=${JSON.stringify({
      customField1: iccid
    })}`)
}

// 白名单列表
const whiteList = [
  '/query',
  '/fb-add',
  '/fb-list',
  '/fb-type',
  '/fb-new',
  '/fb-info',
  '/personal-newcarduser',
  '/personal-bindMobile',
  '/personal-noShowIccidBindMobile',
  '/personal-authenTication',
  '/personal-authSystem',
  '/personal-authUpload',
  '/realName-success',
  '/realName-fail',
  '/realName-reject',
  '/realName-wait',
  '/realName-course',
  '/whiteListQuery',
  '/receive-card',
  '/receive-result',
  '/product-list',
  '/pay-result',
  '/myCards',
  '/addCard',
  // 新全国电信新增百名单页面
  '/new-auth-telecom',
  '/new-realName-course',
  '/service-landing',
  '/service-landing-1'
]

const routes = [
  // 以下重定向兼容原平台路径
  { path: '/', redirect: { name: 'card-query' } },
  { path: '/xm-query', redirect: { name: 'card-query' } },
  // 实名中转页
  { path: '/xm-realNameHub', redirect: { name: 'personal-authenTication' } },
  // 三要素实名
  { path: '/xm-realNameLink', redirect: { name: 'personal-authSystem' } },
  // 白名单中转页   缺失页面
  {
    path: '/whiteListQuery',
    name: 'whiteListQuery',
    meta: {
      title: '-白名单查询'
    },
    component: () => import('_VIEWS_/TransferPage/whiteListQuery.vue')
  },
  // 短信发送页
  { path: '/xm-sendSms', redirect: { name: 'personal-authenTication' } },
  {
    path: '/query',
    name: 'card-query',
    meta: {
      title: '-查询'
    },
    component: () => import('_VIEWS_/CardRecharge/card-query.vue')
  },
  {
    path: '/personal',
    name: 'personal',
    meta: {
      title: '-首页'
    },
    component: () => import('_VIEWS_/PersonalCenter/index')
  },
  {
    path: '/yy-platfrom',
    name: 'yy-platfrom',
    meta: {
      title: '-首页'
    },
    component: () => import('_VIEWS_/yyPlatfrom/index.vue')
  },
  {
    path: '/af-platfrom',
    name: 'af-platfrom',
    meta: {
      title: ''
    },
    component: () => import('_VIEWS_/afPlatfrom/index.vue')
  },
  {
    path: '/fb-add',
    name: 'fb-add',
    meta: {
      title: '-新增反馈'
    },
    component: () => import('_VIEWS_/FeedBack')
  },
  {
    path: '/fb-list',
    name: 'fb-list',
    meta: {
      title: '-反馈列表'
    },
    component: () => import('_VIEWS_/FeedBack/fb-list')
  },
  {
    path: '/fb-type',
    name: 'fb-type',
    meta: {
      title: '-反馈类型'
    },
    component: () => import('_VIEWS_/FeedBack/fb-type')
  },
  {
    path: '/fb-new',
    name: 'fb-new',
    meta: {
      title: '-反馈描述'
    },
    component: () => import('_VIEWS_/FeedBack/fb-new')
  },
  {
    path: '/fb-info',
    name: 'fb-info',
    meta: {
      title: '-反馈互动'
    },
    component: () => import('_VIEWS_/FeedBack/fb-info')
  },
  {
    path: '/message-notice',
    name: 'message-notice',
    meta: {
      title: '-公告通知'
    },
    component: () => import('_VIEWS_/PersonalCenter/messageNotice')
  },
  {
    path: '/message-content',
    name: 'message-content',
    meta: {
      title: '-公告内容'
    },
    component: () => import('_VIEWS_/PersonalCenter/messageContent')
  },
  {
    path: '/usage-record',
    name: 'usage-record',
    meta: {
      title: '-使用量'
    },
    component: () => import('_VIEWS_/UsageRecord')
  },
  {
    path: '/personal-packageRecharge',
    name: 'personal-packageRecharge',
    meta: {
      title: '-套餐充值'
    },
    component: () => import('_VIEWS_/PersonalCenter/packageRecharge')
  },
  {
    path: '/intelligent-diagnosis',
    name: 'intelligent-diagnosis',
    meta: {
      title: '-智能诊断'
    },
    component: () => import('_VIEWS_/PersonalCenter/intelligentDiagnosis')
  },
  {
    path: '/personal-authenTication',
    name: 'personal-authenTication',
    meta: {
      title: '-实名认证'
    },
    component: () => import('_VIEWS_/PersonalCenter/authenTication')
  },
  {
    path: '/realName-success',
    name: 'realName-success',
    meta: {
      title: '-认证成功'
    },
    component: () => import('_VIEWS_/PersonalCenter/realNameSuccess')
  },
  {
    path: '/realName-fail',
    name: 'realName-fail',
    meta: {
      title: '-认证失败'
    },
    component: () => import('_VIEWS_/PersonalCenter/realNameFail')
  },
  {
    path: '/realName-reject',
    name: 'realName-reject',
    meta: {
      title: '-认证驳回'
    },
    component: () => import('_VIEWS_/PersonalCenter/realNameReject')
  },
  {
    path: '/realName-wait',
    name: 'realName-wait',
    meta: {
      title: '-等待审核'
    },
    component: () => import('_VIEWS_/PersonalCenter/realNameWait')
  },
  {
    path: '/personal-setMeal',
    name: 'personal-setMeal',
    meta: {
      title: '-账单中心'
    },
    component: () => import('_VIEWS_/PersonalCenter/setMeal')
  },
  {
    path: '/personal-cardManagement',
    name: 'personal-cardManagement',
    meta: {
      title: '-卡片管理'
    },
    component: () => import('_VIEWS_/PersonalCenter/cardManagement')
  },
  {
    path: '/personal-shortMessage',
    name: 'personal-shortMessage',
    meta: {
      title: '-短信管理'
    },
    component: () => import('_VIEWS_/PersonalCenter/shortMessage')
  },
  {
    path: '/personal-changecard',
    name: 'personal-changecard',
    meta: {
      title: '-换卡管理'
    },
    component: () => import('_VIEWS_/PersonalCenter/changeCard')
  },
  {
    path: '/personal-changeImei',
    name: 'personal-changeImei',
    meta: {
      title: '-机卡绑定'
    },
    component: () => import('_VIEWS_/PersonalCenter/changeImei')
  },
  {
    path: '/personal-whiteList',
    name: 'personal-whiteList',
    meta: {
      title: '-白名单'
    },
    component: () => import('_VIEWS_/PersonalCenter/whiteList')
  },
  {
    path: '/yyPlatfrom-familyList',
    name: 'yyPlatfrom-familyList',
    meta: {
      title: '-添加亲情号'
    },
    component: () => import('_VIEWS_/yyPlatfrom/familyList')
  },

  {
    path: '/personal-whiteListAdd',
    name: 'personal-whiteListAdd',
    meta: {
      title: '-白名单'
    },
    component: () => import('_VIEWS_/PersonalCenter/whiteListAdd')
  },
  {
    path: '/yyPlatfrom-familyListAdd',
    name: 'yyPlatfrom-familyListAdd',
    meta: {
      title: '-新增亲情号'
    },
    component: () => import('_VIEWS_/yyPlatfrom/familyListAdd')
  },
  {
    path: '/stop-recharge',
    name: 'stop-recharge',
    meta: {
      title: '-停机保号'
    },
    component: () => import('_VIEWS_/PersonalCenter/stopRecharge')
  },
  {
    path: '/personal-newcarduser',
    name: 'personal-newcarduser',
    meta: {
      title: '-新卡用户'
    },
    component: () => import('_VIEWS_/PersonalCenter/newcarduser')
  },
  {
    path: '/receive-card',
    name: 'receive-card',
    meta: {
      title: '-卡片领取'
    },
    component: () => import('_VIEWS_/PersonalCenter/receiveCard')
  },
  {
    path: '/receive-result',
    name: 'receive-result',
    meta: {
      title: '-结果页'
    },
    component: () => import('_VIEWS_/PersonalCenter/receiveResult')
  },
  {
    path: '/product-list',
    name: 'product-list',
    meta: {
      title: '-产品列表'
    },
    component: () => import('_VIEWS_/PersonalCenter/productList')
  },
  {
    path: '/personal-bindMobile',
    name: 'personal-bindMobile',
    meta: {
      title: '-手机绑定'
    },
    component: () => import('_VIEWS_/PersonalCenter/bindMobile')
  },
  {
    path: '/personal-addNewCard',
    name: 'personal-addNewCard',
    meta: {
      title: '-新增卡片'
    },
    component: () => import('_VIEWS_/PersonalCenter/addNewCard')
  },
  {
    path: '/personal-authSystem',
    name: 'personal-authSystem',
    meta: {
      title: '-系统实名'
    },
    component: () => import('_VIEWS_/PersonalCenter/authSystem')
  },
  {
    path: '/personal-authUpload',
    name: 'personal-authUpload',
    meta: {
      title: '-手动实名'
    },
    component: () => import('_VIEWS_/PersonalCenter/authUpLoad')
  },
  {
    path: '/transfer-page',
    name: 'transfer-page',
    meta: {
      title: '-中转页面'
    },
    component: () => import('_VIEWS_/TransferPage')
  },
  {
    path: '/pay-result',
    name: 'pay-result',
    meta: {
      title: '-支付管理'
    },
    component: () => import('_VIEWS_/PayResult')
  },
  {
    path: '/netWork-setting',
    name: 'netWork-setting',
    meta: {
      title: '-网络设置'
    },
    component: () => import('_VIEWS_/PersonalCenter/netWorkSetting')
  },
  {
    path: '/realName-course',
    name: 'realName-course',
    meta: {
      title: '-实名教程'
    },
    component: () => import('_VIEWS_/PersonalCenter/realNameCourse')
  },
  {
    path: '/batch-refund',
    name: 'batch-refund',
    meta: {
      title: '-退款进度'
    },
    component: () => import('_VIEWS_/PersonalCenter/batchRefund')
  },
  {
    path: '/invoiced-list',
    name: 'invoiced-list',
    meta: {
      title: '-可开票列表'
    },
    component: () => import('_VIEWS_/PersonalCenter/invoicedList')
  },
  {
    path: '/invoiced-order',
    name: 'invoiced-order',
    meta: {
      title: '-所含订单'
    },
    component: () => import('_VIEWS_/PersonalCenter/invoicedOrder')
  },
  {
    path: '/invoiced-detail',
    name: 'invoiced-detail',
    meta: {
      title: '-发票详情'
    },
    component: () => import('_VIEWS_/PersonalCenter/invoicedDetail')
  },
  {
    path: '/apply-invoiced',
    name: 'apply-invoiced',
    meta: {
      title: '-申请开票'
    },
    component: () => import('_VIEWS_/PersonalCenter/applyInvoiced')
  },
  {
    path: '/submit-result',
    name: 'submit-result',
    meta: {
      title: '-提交结果'
    },
    component: () => import('_VIEWS_/PersonalCenter/submitResult')
  },
  {
    path: '/new-auth-telecom',
    name: 'new-auth-telecom',
    meta: {
      title: '-新实名认证'
    },
    component: () => import('_VIEWS_/PersonalCenter/newAuthTelecom')
  },
  {
    path: '/new-realName-course',
    name: 'new-realName-course',
    meta: {
      title: '-新实名教程'
    },
    component: () => import('_VIEWS_/PersonalCenter/newRealNameCourse')
  },
  {
    path: '/coupon-list',
    name: 'couponList',
    meta: { title: '-我的券' },
    component: () => import('_VIEWS_/Coupon/list')
  },
  {
    path: '/recharge-guidance',
    name: 'recharge-guidance',
    meta: { title: '-为什么充值流量?' },
    component: () => import('_VIEWS_/afPlatfrom/rechargeGuidance')
  },
  {
    path: '/myCards',
    name: 'myCards',
    meta: { title: '我的卡片' },
    component: () => import('_VIEWS_/myCards')
  },
  {
    path: '/addCard',
    name: 'addCard',
    meta: { title: '添加卡片' },
    component: () => import('_VIEWS_/myCards/addCard')
  },
  // 直播电商客服
  {
    path: '/service-landing',
    name: 'service-landing',
    meta: { title: '联系客服' },
    component: () => import('_VIEWS_/serviceLanding/index.vue')
  },
  // 一类电商客服
  {
    path: '/service-landing-1',
    name: 'service-landing-1',
    meta: { title: '联系客服' },
    component: () => import('_VIEWS_/serviceLanding-1/index.vue')
  },
  {
    path: '/personal-noShowIccidBindMobile',
    name: 'personal-noShowIccidBindMobile',
    meta: {
      title: '-实名绑定'
    },
    component: () => import('_VIEWS_/PersonalCenter/noShowIccidBindMobile')
  },
]

const router = createRouter({
  history: createWebHistory(),
  base: '/',
  routes
})

router.beforeEach((to, from, next) => {
  sessionStorage.setItem('beforeRoute', from.path ? `${from.path}----${from.meta.title || '登录'}` : '--')
  document.title = `流量管理平台${to.meta.title}`
  // 获取主题配置
  const themeName = getStore({ name: 'themeName' })
  // 获取签约状态  true 未签约，false 已签约
  const contractStatus = getStore({ name: 'contractStatus' })
  // 用户信息
  const userInfo = getStore({ name: 'userInfo' })
  // 获取用户登录信息
  const loginAccessToken = getStore({ name: 'loginAccessToken' })
  if (!to.name) {
    return router.push({
      name: 'card-query',
      query: {
        iccid: to.query && to.query.iccid ? to.query.iccid : ''
      }
    })
  }
  NProgress.start()
  // 前往的是工单反馈 且 有智齿客服
  if (window.SITE_CONFIG.kefu_URL && to.name.indexOf('fb-') > -1) {
    location.href = window.SITE_CONFIG.kefu_URL
    NProgress.done()
    return
  }

  // 没登录 并且 前往的 是 绑定手机页
  if (!loginAccessToken && to.name == 'personal-bindMobile') {
    next()
    NProgress.done()
    return
  }

  // 前往的 非 查询页 并且 没登录 并且 没有在白名单列表
  if (
    to.name != 'card-query' &&
    !loginAccessToken &&
    whiteList.indexOf(to.path) == -1
  ) {
    next({
      name: 'card-query'
    })
    NProgress.done()
    return
  }
  // 语音平台 且 已登录 且 前往的为 查询页 或 旧的个人中心
  if (themeName === 'YuYin' && Boolean(loginAccessToken) && (to.name == 'card-query' || to.name == 'personal')) {
    next({
      name: 'yy-platfrom'
    })
    NProgress.done()
    return
  }
  // 安防平台 且 已登录 且 前往的为 查询页 或 旧的个人中心
  if (themeName === 'AnFang' && Boolean(loginAccessToken) && (to.name == 'card-query' || to.name == 'personal')) {
    next({
      name: 'af-platfrom'
    })
    NProgress.done()
    return
  }
  // 已登录 且 前往的为 套餐充值页 且 未签约
  if (Boolean(loginAccessToken) && to.name == 'personal-packageRecharge' && contractStatus) {
    Dialog.confirm({
      title: '系统提示',
      message: '暂无法充值，原因包括但不限于签约未完成，请联系客服',
      confirmButtonText: '联系客服',
      confirmButtonColor: '#1989fa',
      cancelButtonText: '关闭',
      cancelButtonColor: '#000'
    })
      .then(() => {
        keFuHandle(userInfo.iccid)
      })
      .catch(() => {
        window.history.back()
      })
    NProgress.done()
    return
  }
  // 已登录 且 前往的为 查询页
  if (Boolean(loginAccessToken) && to.name == 'card-query') {
    next({
      name: 'personal'
    })
    NProgress.done()
    return
  }
  // 如果进入到问题反馈，不拦截路由
  if (Boolean(to.name) && to.name.indexOf('fb-') > -1) {
    if (
      !(store.state.user.queryIccid || loginAccessToken) &&
      to.name != 'fb-add'
    ) {
      next({
        name: 'fb-add'
      })
      return
    }
    next()
    return
  }
  next()
})

router.afterEach(() => {
  NProgress.done()
})

export default router
